import ListAlerts from 'components/ListAlerts';
import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredData } from 'hooks/useFetchFilteredData';

const Categories = () => {
  const { isOzonPlatform, isFilterApplied } = useFilterReducer();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredData({
    type: TABLE_TYPE.CATEGORIES_GROWTH,
    depth: isFilterApplied ? 0 : 1,
  });

  return (
    <>
      <Table
        data={data}
        dataPagesKey={'data'}
        type={TABLE_TYPE.CATEGORIES_GROWTH}
        paginationOnBack={true}
        hideSoldColumn={isOzonPlatform}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Categories;
