import ListAlerts from 'components/ListAlerts';
import Table from 'components/Table';
import { TABLE_TYPE } from 'constants/table';
import { useFilterReducer } from 'context/filter/filter.context.reducer';
import { useFetchFilteredDataPaginated } from 'hooks/useFetchFilteredData';

const Brands = () => {
  const { isOzonPlatform } = useFilterReducer();

  const { data, isLoading, isFetching, hasNextPage } = useFetchFilteredDataPaginated({
    type: TABLE_TYPE.BRANDS_GROWTH,
  });

  return (
    <>
      <Table
        data={data}
        dataPagesKey={'data'}
        type={TABLE_TYPE.BRANDS_GROWTH}
        hideSoldColumn={isOzonPlatform}
        paginationOnBack={true}
        isLoading={isLoading || (isFetching && !hasNextPage)}
        noRowsText={`По вашему запросу нет данных.<br/> Попробуйте изменить фильтры`}
      />
      <ListAlerts />
    </>
  );
};

export default Brands;
